.operatorSubmissionSummaryContainer {
    position: relative;
    border: 1px solid #d2d2d2;
    border-radius: 4px; 
    background-color: white; 
    text-align: left;
    font-size: 10pt; 
    color: #999999;
    padding: 20px;  
    margin-bottom: 10px;
}

.whiteText {
    color: white;
}

.whiteText:hover {
    color: white;
}