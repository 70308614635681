html, body, #root {
  height: 100%;
}

*:focus {
    outline: 0 !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

body {
  position: relative;   
  margin: 0;
  padding: 180px 0 0 0;
  /*font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;*/
  background: none;
  background-color: #161823;
}

.table>thead>tr>th {
  text-align: left !important;
}

.heading {
  font-weight: bold;
  font-size: 16px;
}

.headingPopUp {
  font-weight: bold;
  font-size: 12px;
}

.center {
  text-align: center;
}

.spacer {
  margin: 20px 0 20px 0;
}

.pageWrap {
  min-height: 100%;  
  margin-bottom: -70px; 
}
.pageWrap:after {
  content: "";
  display: block;
}
.pageWrap:after {
  min-height: 70px; 
}

.col-sm-4 {
    padding-left: 0; 
}

.alignRight {
  text-align: right;
}

/* Generic module styles */
.publishDateContainer {  
  height: 190px;
  background-color: black; 
  border-radius: 10px;
  padding: 20px; 
  box-sizing: border-box;   
}
.publishDateContainer p {
  color: white;
}

.moduleContainer {
  position: relative;
  background-color: black; 
  border-radius: 10px;
  padding: 20px; 
  box-sizing: border-box;
  color: white;
}

.moduleContainer .container {
  width: 100%;
}

.moduleContainer h2, .publishDateContainer h2 {
  margin: 0 0 20px 0; 
  color: white;
  text-transform: capitalize;
  font-size: 16pt;
  font-weight: 300; 
  border-bottom: 1px solid #393a43;
  padding-bottom: 18px; 
}

.moduleContainer h2 .test {
  right: 20px;
  top: 20px; 
}

.moduleContainer.alternative {
  background-color: #40424b;  
}
.moduleContainer.addNewUser{
  height: 390px;
}
.form-check-label{
  margin-left: 6px;
  font-weight: normal;
}
.moduleContainer .btn-ligth,.btn-light{
  color: #333;
}
.moduleContainer .btn-ligth:hover,.btn-light:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.dvCapcity{
  height: 390px;
}
.publisherDate{
  min-height: 1px;
  padding-right: 15px;
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: 0;
  text-align: unset !important;
}
.react-datepicker{
  font-size: 1.0rem !important;
}


/* Generic table styles */
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td {
  background-color: #161823;
  border: 0;
  border-bottom: 1px solid #282a34;
  padding: 6px;
  vertical-align: middle;
}
.table>thead>tr>th {
  font-weight: 300; 
  color: #8b8b8d;
  border-bottom: 0;
  text-align: center;
}

.modal-backdrop {
  background-color: #0e0f1a;
  opacity: 0.8;
}

.table-responsive {
  border: 0;
}
.navbar-default .navbar-collapse {
  background-color: #161823;
  border: 0;
}

#regulator-view, .pageWrap {
  position: relative;
}


@media (max-width: 767px) {

  .navbar-nav>li {
    border-bottom: 1px solid #282a34;
    text-align: center;
  }

}