.reminderNoticeContainer {
    height: 54px; 
    background-color: #ff4141;
    text-align: center; 
    line-height: 70px;
    color: #ffffff;
    font-size: 12pt;    
}
.reminderNoticeContainer span {
    background: url(../../gui/images/submission-due.png) no-repeat;
    padding-left: 30px;
}
.example-enter {
  height: 0px;
}

.example-enter.example-enter-active {
  height: 54px;
  -webkit-transition: height 1s ease;
}