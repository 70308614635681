.currentTotalViewContainer .col-sm-4 {
    padding-left: 0; 
}

.pieChartContainer {
    margin-bottom: 10px; 
}

.pieChartContainer:last-child {
    margin-bottom: 0;
}

.submittedDate {
    position: absolute;
    bottom: 20px; 
    right: 20px; 
    font-style: italic;
}