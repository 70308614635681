.loading-container {
    position: fixed;
    background-color:rgba(0,0,0,0.5);  
    width: 100%; 
    min-height: 100%;
    /*z-index: 100;*/
    top: 0; 
    left: 0;
    text-align: center;
    margin: 0;
}
.loading-container > div:first-child {
    position: fixed;
    width: 100%;
    top: 50%; 
    margin: 0 auto 0 auto;
}
/*
2018-01-18T00:00:00.000Z 
2018-01-18T00:00:00.000Z
*/