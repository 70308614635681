#activate-view {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; 
    background: url(../../gui/images/background1.jpg) no-repeat fixed;
    background-size: cover; 
}
.activateContainer {
    position: relative;
    margin: 0;
    max-width: 400px; 
    max-height: 768px;
    box-sizing: border-box;
    top: 50%; 
    margin: -200px auto; 
    background-color: rgba(22,24,35,0.9); 
    border-radius: 2px; 
    padding: 20px; 
}
    .activateContainer .titleContainer {
        text-align: left;    
        color: #ffffff;
    }
    .activateContainer .error {
        color: red;
        font-size: 12pt;
    }
    .activateContainer .activate-button {
        width: 100%; 
        height: 53px; 
        border-top: 1px solid #2f8ce7;
    }


#activate-view .loginLogo {
    position: absolute; 
    top: 40px; 
    left: 40px; 
    background: url(../../gui/images/loginLogo.png) no-repeat;
    width: 126px;
    height: 95px; 
}