.center-content {
  text-align: center;
  vertical-align: middle;
}

.right-content {
  text-align: right;
}

.status-indicator {
  display: inline-block;
  width: 32px; 
  height: 32px; 
}

.approved {
  background: url(../../gui/images/approved.png) no-repeat;  
}

.neutral {
  background: url(../../gui/images/waiting-approval.png) no-repeat;  
}

.declined {
  background: url(../../gui/images/declined.png) no-repeat;  
}

.active {
  color: green;
}
.inactive {
  color: red;
}

.submission-history-link {
  color: white;
}

.capacity-toggle {
  position: absolute;  
  right: 20px; 
  top: 20px;     
  color: white;
  cursor: pointer;
}