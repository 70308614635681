#login-view {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; 
    background: url(../../gui/images/background1.jpg) no-repeat fixed;
    background-size: cover; 
}
.loginContainer {
    position: absolute;
    margin: 0;
    max-width: 300px; 
    max-height: 768px;
    box-sizing: border-box;
    bottom: 40px; 
    left: 40px; 
    margin-right: 40px; 
    background-color: rgba(22,24,35,0.9); 
    border-radius: 2px;
    padding: 20px; 
}

.loginLogo {
    position: absolute; 
    top: 40px; 
    left: 40px; 
    background: url(../../gui/images/loginLogo.png) no-repeat;
    width: 126px;
    height: 95px; 
}

.loginContainer .titleContainer {
    text-align: left;    
    color: #ffffff;
}

.loginContainer .formContainer {
    box-sizing: border-box;    
}

.loginContainer .formContainer label {
    text-transform: uppercase;   
    color: #686a71;  
    font-weight: 300;
}

.loginContainer .formContainer .padding {
    padding: 20px; 
    box-sizing: border-box;    
}

.loginContainer .login-button {
    width: 100%; 
    height: 53px; 
    border-top: 1px solid #2f8ce7;
}

.loginContainer .forgotLink {
    margin: 0;
    padding: 0;
}

.loginContainer .form-control {
    background-color: #161823;
    border: 0;
    padding-left: 20px; 
    outline: none;
    box-shadow: none;
    color: white;
}

.loginContainer .username {
    background: url(../../gui/images/username.png) no-repeat left center;
}

.loginContainer .password {
    background: url(../../gui/images/password.png) no-repeat left center;
}

.loginContainer .auth {
    background: url(../../gui/images/auth.png) no-repeat left center;
}