.currentDateContainer {
    background-color: black;
    padding: 20px; 
    text-align: center;
    color: white;     
    margin-bottom: 20px; 
    border-radius: 10px;
}
.currentDateContainer h4 {
    font-size: 20pt;
}