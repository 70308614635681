.center {
    text-align: center;
}
.information {
    position: absolute;
    left: 25px;
    width: 160px; 
    height: 160px; 
}
.information h3 {
    color: white; 
    padding: 0;
    margin: 65px 0 0 0;    
    text-align: center;
    font-weight: 300;
}
.information span {
    display: block;
    color: white;
    text-align: center;
    font-weight: 100;
}