h2 {
    text-transform: uppercase;
    font-weight: bold; 
    font-size: 16px;
}

.summaryContainer h3 {
    border-bottom: 1px solid #999999;
    color: #999999;
    font-size: 16px;  
    font-weight: 300;
    padding-bottom: 20px; 
}

.summaryContainer span {
    display: block; 
    font-size: 32px; 
    color: #3399cc;
    border-bottom: 1px solid lightgray; 
    padding-bottom: 10px; 
}

.summaryContainer p {
    padding: 10px 0 10px 0;
    font-size: 12px; 
    color: lightgrey;
}

/* Override Bootstrap Grid Padding */
.col-md-4 {
    padding-right: 0; 
    padding-left: 0; 
}