.mainContainer {
  height: 100%;   
}

.contentContainer {
  padding: 20px; 
}

.navigationContainer {
  position: fixed;
  top: 0;
  width: 100%; 
  z-index: 1;
}


.navbar {  
  height: 101px;
  width: 100%;  
  z-index: 999;
  min-height: 100px;
}

.navbar button {
  margin-top: 28px;  
  font-size: 12pt !important;  
  text-decoration: none !important;
}

.navbar .btn-group-vertical>.btn, .navbar .btn-group>.btn {
  float: none;
  line-height: 0;
}

.navbar .dropdown {
  height: 100px; 
  line-height: 100px;
}

.navbar .dropdown-menu {
  background-color: #161823;  
  top: 36px; 
}

.navbar .dropdown-menu>li>a,.dropdown-menu>a {
  color: #fff;  
  font-size: 12pt;
  text-decoration: none;
}
.dropdown-menu>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.4;
    white-space: nowrap;
}
.navbar .dropdown-menu>li>a:hover {
  color: lightgrey;
  background-color: #161823;
}

.navbar-default .btn-link:focus, .navbar-default .btn-link:hover {
  color: white; background: none;
}

.navbar-default {  
  background-color: #161823;
  border: 0;
  border-bottom: 1px solid #393a43;
}
.navbar-brand {
  height: 100px;   
}

.navbar-nav>li>a {
  line-height: 68px;
  font-size: 12pt;
  text-transform: uppercase;  
  padding-top: auto; 
  padding-bottom: auto;
  color: #fff !important;
}

.navbar-default .navbar-nav>.active>a, 
.navbar-default .active .btn-link, 
.navbar-default .navbar-nav>.active>a:hover {
  background-color: #161823; 
  font-weight: bold;  
  color: #ffffff;
}

.navbar-default .navbar-nav>li>.active{
  background-color: #161823; 
  font-weight: bold;  
  color: #ffffff;
}

.navbar-right {
  height: 100px;
  line-height: 100px;
}
.userDetails {
  margin-top: 40px;
  margin-left: 20px; 
  float: left;
  line-height: 12pt;
  text-align: right;
  margin-right: 20px;
  color: #a2a3a7;
}

.userDetails span {
  display: block; 
  color: white; 
  font-weight: bold; 
}

.profileImage {
  margin-top: 30px;
  float: right;
  width: 45px;
  height: 45px; 
  border-radius: 22px;
  background-color: #4a4b51;
}
.browser-notification {
  display:flex;
  align-items: center; /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgba(0,0,0,0.8);
  text-align: center;
  vertical-align: middle;
}
.browser-notification span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: white; 
  font-weight: bold;
}
.valid-feedback{
  margin-bottom: 15px;
}
form div.col-sm-2 {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}
#dropdown-basic{
  color: white;
}