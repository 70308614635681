.alertModal .modal-content {
    background-color: #161823;
    color: white; 
    border-radius: 0; 

}
.alertModal .modal-content .modal-header {
    background-color: #169c00;
    height: 50px; 
    border-bottom: 0;
}

.alertModal .modal-content .modal-header .modal-title {
    font-size: 14pt; 
    font-weight: 300;
    margin-left: 10px;
    height: 50px; 
    line-height: 25px; 
}

.alertModal .modal-body h3 {
    margin: 0;
    color: #a1a1a4;
    font-weight: 300; 
    font-size: 16pt;
    border-bottom: 1px solid #2e3039;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.alertModal .modal-body h3 span {
    color: white; 
}
.alertModal .modal-footer {
    border-top: 1px solid #2e3039;
    padding: 20px;
    text-align: center;
}
.alertModal .okButton {
    width: 100px; 
}