.test {
    position: absolute;    
    background-color: black;
    text-align: right;
}

.test button {
    background: none; 
    border: none;
}

.test ul {
    list-style: none;
}