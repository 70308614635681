#footer-view {
    background-color: #161823;
    color: white; 
    min-height: 70px;     
    text-align: center;
    box-sizing: border-box;
    border-top: 1px solid #393a43;
    position: fixed;
    bottom: 0;
    width: 100%;
}

#footer-view p {
    padding-top: 30px; 
    width: 49%;
    display: inline-block; 
    margin: 0;
    text-align: left;    
}

#footer-view p:last-child {    
    text-align: left;
    text-align: right;
    padding-bottom: 30px;
}

#footer-view a {
    color: white; 
    text-decoration: underline;
}