.submissionModal {
    width: 100%; 
}
.submissionModal .modal-content {
    background-color: #161823;
    color: white; 
    border-radius: 0; 

}
.submissionModal .modal-content .modal-header {
    background-color: #ff4141;
    height: 50px; 
    border-bottom: 0;
}
.modalheight .modal-dialog .modal-content{
    height: 230px;
}

.submissionModal .modal-content .modal-header.complete {
    background-color: #169c00;
}

.submissionModal .modal-content .modal-header .modal-title {
    font-size: 10pt; 
    font-weight: 300;
    background: url(../../gui/images/lock-icon.png) no-repeat;
    background-size: 16px 24px;
    padding-left: 30px;
    margin-left: 10px;
    height: 50px; 
    line-height: 25px; 
}

.submissionModal .modal-body h3 {
    margin: 0;
    color: #a1a1a4;
    font-weight: 300; 
    font-size: 16pt;
    border-bottom: 1px solid #2e3039;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.submissionModal .modal-body h3 span {
    color: white; 
}

.submissionModal input {
    background-color: #2e3039;
    border: 0;
    color: white;
    font-weight: 300;
}

.submissionModal .modal-footer {
    border-top: 1px solid #2e3039;
    padding: 0;
}

.submissionModal .modal-footer .col-sm-6 {
    padding: 0;
}

.submissionModal .submitButton {
    height: 50px; 
    width: 100%;
    margin: 0;
    border-radius: 0;
}

.submissionModal .cancel {
    background-color: #161823; 
    color: #6e6f76;
    border: 0;
}

.submissionModal .previousSubmissions h4 {
    font-weight: 300; 
    font-size: 10pt; 
    color: #a1a1a4; 
}
.submissionModal .previousSubmissions h4 span {
    color: white; 
}

.submissionModal .previousSubmissions .commodityValue {
    display:inline-block;
    padding-right: 60px;
}

.submissionModal .previousSubmissions .light {
    color: #08c3a5;
}

.submissionModal .previousSubmissions .middle {
    color: #f36c35;
}

.submissionModal .previousSubmissions .heavy {
    color: #c40067;
}

.submissionModal .form-horizontal .form-group {
    margin-left: 0;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 700px; 
    }
}

.validation {
    background-color: rgba(255,65,65,0.5);
    border: 1px solid #ff4141;
    border-radius: 2px;
    text-align: left; 
    color: #ffffff;
    padding: 20px; 
    margin: 20px 0 20px 0;
    font-size: 12pt;
    
}

