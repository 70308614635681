.progressContainer h2 {
    margin-top: 80px; 
    text-align: center;
    text-transform: capitalize;
    font-weight: 300;
    font-size: 14pt;
}

.progressImages {
    text-align: center;
}

.progressImages img {
    margin: 0 20px 100px 20px;
}

.progress-bar {
    background-color: green;
  }