.center-content {
  text-align: center;
  vertical-align: middle;
}

.status-indicator {
  display: inline-block;
  width: 32px; 
  height: 32px; 
}

.approved {
  background: url(../../gui/images/approved.png) no-repeat;  
}

.neutral {
  background: url(../../gui/images/waiting-approval.png) no-repeat;  
}

.declined {
  background: url(../../gui/images/declined.png) no-repeat;  
}