.publishedTotalContainer .lower {
    color: red;     
}

.publishedTotalContainer .noChange {
    color: grey;    
}

.publishedTotalContainer .higher {
    color: green;    
}
.recharts-responsive-container {
    position: relative;
}
.publishedTotalContainer .display-information {
    position: absolute;
    left: 0;
    width: 100%; 
    height: 100%;
}
.publishedTotalContainer .display-information h3 {
    color: white; 
    padding: 0;
    margin: 10px 0 0 0;    
    text-align: center;
    font-weight: 300;
    font-size: 24pt;
    margin-right: 15px; 
}
.publishedTotalContainer .display-information .commodityTitle {
    display: block;
    color: white;
    text-align: center;
    font-weight: 100;
    margin-right: 15px; 
}
.publishedTotalContainer p {
    margin: 80px 20px 0 0;       
    text-align: center;
}